import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentChips.figmaUrl.ios} codeUrl={checklists.componentChips.codeUrl.ios} checklists={checklists.componentChips.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Chips allow users to enter information, make choices, filter content, or trigger actions. While buttons are expected to appear consistently and with a familiar call to action, chips will appear dynamically as groups of multiple interactive elements.`}</p>
    <p>{`Use Legion chips styles to modify chips with lots of attributes that make you easier.`}</p>
    <p>{`ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNChips(label: "Label")
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/ios.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  LGNChips(label: "Label", size: .medium)
  LGNChips(label: "Label", size: .small)
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`Legion Have 3 Attributes for Customize Chips :`}</p>
    <h3><strong parentName="h3">{` Size `}</strong></h3>
    <p>{`This size attribute for user to costumize size of chips`}</p>
    <div {...{
      "className": "item-three-container"
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Small`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/labelsmall.png"
          }}></img>{`
    `}</div>{`
    Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNChips(label: "Label", size: .small)`}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Medium`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/labelmedium.png"
          }}></img>{`
    `}</div>{`
    Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNChips(label: "Label", size: .medium)`}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <h3><strong parentName="h3">{` Active `}</strong></h3>
    <p>{`This size attribute for user to costumize active of chips`}</p>
    <div {...{
      "className": "item-three-container"
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Small`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/labelactivesmall.png"
          }}></img>{`
    `}</div>{`
    Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNChips(label: "Label", isActive: true, size: .small)`}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Medium`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/labelactivemedium.png"
          }}></img>{`
    `}</div>{`
    Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNChips(label: "Label", isActive: true, size: .medium)`}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <h3><strong parentName="h3">{` Disable `}</strong></h3>
    <p>{`This size attribute for user to costumize disable of chips`}</p>
    <div {...{
      "className": "item-three-container"
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Small`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/labeldisablesmall.png"
          }}></img>{`
    `}</div>{`
    Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNChips(label: "Label", isDisabled: .constant(true), size: .small)`}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Medium`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/labeldisablemedium.png"
          }}></img>{`
    `}</div>{`
    Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNChips(label: "Label", isDisabled: .constant(true), size: .medium)`}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Properties`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Default Value`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the chip’s label, that describes the purpose of the chips’ action.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'nil'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the chips. Either `}<inlineCode parentName="td">{`.medium`}</inlineCode>{` or `}<inlineCode parentName="td">{`.small`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'nil'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isDisabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A binding boolean to enable disable the chips component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'.constant(false)'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isActive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The boolean state to enable active state to the chips component.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'false'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string of font family name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action to perform when the user triggers the chips.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isHiddenBorder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The boolean state to hide the border when the state is active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'false'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`activeTextColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A text color to be used for the foregroundColor/textlabel when the state is active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'.white'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabledTextColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A text color to be used for the foregroundColor/textlabel when the state is disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'Color.LGNTheme.tertiary400'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultTextColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A text color to be used for the foregroundColor/textlabel when the state is default`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'Color.LGNTheme.tertiary900'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`activeBackgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A background color to be used for the chips background when the state is active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'Color.LGNTheme.tertiary900'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`notActiveBackgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A background color to be used for the chips background when the state is not active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'.clear'`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example Project`}</h2>
    <p>{`ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`  LGNChips(
  label: "Hello",
  isDisabled: .constant(false),
  isActive: false,
  size: .small,
  fontFamily: "NunitoSans-Bold"
) {
  print("hello")
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      